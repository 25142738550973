import { createApplication, entityConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { ApplicationLogo, ApplicationLogoInverted } from './components';

const theme = themes.VILLARS_THEME;

// pre-patch configs to ensure that derived properties are correctly created

const { Organisation, Page } = entityConfigs;

if (Organisation) {
  Organisation.extraFields = {
    trends: { type: 'linkedMultiple', entityTypeName: 'Trend', fieldSet: 'taxonomies' },
    topics: { type: 'linkedMultiple', entityTypeName: 'Topic', fieldSet: 'taxonomies' },
  }
}

if (Page) {
  Page.extraFields = {
    organisations: { type: 'linkedMultiple', entityTypeName: 'Organisation', fieldSet: 'community' },
  }
}


export const application = createApplication({
  theme,
  entityConfigs,
  options: {
    systemsMapping: 'full',
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.SubTopic;